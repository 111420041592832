html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  /* font-family: "Pretendard", "AppleSDGothicNeo", "Noto Sans KR", sans-serif; */
  font-family: "AppleSDGothicNeoM", "Noto Sans KR", sans-serif;
  word-break: keep-all;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.bi-search {
  float: right;
  margin-right: 10px;
  font-size: 5rem;
  color: #555555;
}
html {
  background-color: black;
  color: white;
  font-size: 62.5%;
  position: relative;
  overflow-x: hidden;
}

@media screen and (max-width: 899px) {
  html {
    /* position: fixed;
    height: 100%;
    overflow: hidden; */
    font-size: calc(100vw / 375 * 10);
  }
}

/* LEGACY LAYOUT CSS */
/* @media screen and (min-width: 900px) and (max-width: 1199px) {
  html {
    font-size: calc(100vw / 1200 * 10);
  }
} */

@media screen and (min-width: 900px) and (max-width: 1919px) {
  html {
    font-size: calc(100vw / 1920 * 10);
  }
}

@media screen and (min-width: 1920px) {
  html {
    font-size: calc(100vw / 1920 * 10);
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.fade-in.fade-in-visible {
  opacity: 1;
}

.fade-in-late {
  opacity: 0;
  transition: opacity 2s ease-in;
}

.fade-in-late.fade-in-visible {
  opacity: 1;
}

@font-face {
  font-family: AppleSDGothicNeoM;
  src: url("./fonts/AppleSDGothicNeoM.ttf");
}

@font-face {
  font-family: AppleSDGothicNeoB;
  src: url("./fonts/AppleSDGothicNeoB.ttf");
}

@font-face {
  font-family: AppleSDGothicNeoEB;
  src: url("./fonts/AppleSDGothicNeoEB.ttf");
}

@font-face {
  font-family: AppleSDGothicNeoH;
  src: url("./fonts/AppleSDGothicNeoH.ttf");
}

@font-face {
  font-family: SCDream8;
  src: url("./fonts/SCDream8.otf");
}

@font-face {
  font-family: SCDream9;
  src: url("./fonts/SCDream9.otf");
}
