/* import from https://codepen.io/cchambers/pen/VYZzaR */

.wrap {
  margin: 0 auto;
  line-height: 6rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.wrap > div {
  font-weight: 300;
  font-size: 2rem;
  line-height: 3.6rem;
  color: #eeeeee;
}

.orbit {
  margin: 28rem auto 0;
  overflow: hidden;
  height: 20rem;
  width: 20rem;
  top: 0;
  overflow: visible;
  border-radius: 50%;
  position: relative;
}

.orbit > div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  top: 2.5rem;
  left: 2.5rem;
  font-size: 1rem;
  height: 15rem;
  width: 15rem;
  transform-origin: center center;
}

.orbit > div::before {
  position: absolute;
  left: calc(50% - 0.5em);
  display: block;
  content: "";
  height: 1em;
  width: 1em;
  border-radius: 50%;
  transform: scale(1);
}

.orbit > div:nth-child(1)::before {
  background-color: #5d7ff2;
}
.orbit > div:nth-child(2)::before {
  background-color: #d50f25;
}
.orbit > div:nth-child(3)::before {
  background-color: #009925;
}
.orbit > div:nth-child(4)::before {
  background-color: #eeb211;
}

.loader.orbit > div {
  animation: loading-orbit 6s infinite linear;
}

.go .loader.orbit > div::before {
  animation: loading-orbit-before 2s infinite ease-in-out;
}

.loader.orbit > div:nth-child(1) {
  animation-delay: 0s;
}
.loader.orbit > div:nth-child(2) {
  animation-delay: -0.75s;
}
.loader.orbit > div:nth-child(3) {
  animation-delay: -1.5s;
}
.loader.orbit > div:nth-child(4) {
  animation-delay: -2.25s;
}

.loader.orbit > div:nth-child(1)::before {
  animation-delay: 0s;
}
.loader.orbit > div:nth-child(2)::before {
  animation-delay: -0.5s;
}
.loader.orbit > div:nth-child(3)::before {
  animation-delay: -1s;
}
.loader.orbit > div:nth-child(4)::before {
  animation-delay: -1.5s;
}

@keyframes loading-orbit {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-orbit-before {
  0% {
    height: 1em;
    width: 1em;
    transform: translate3d(0, 0, 0);
    z-index: 5;
  }
  5% {
    height: 1.25em;
    width: 1.25em;
  }
  25% {
    height: 2em;
    width: 2em;
    z-index: 10;
  }
  50% {
    transform: translate3d(1rem, 15rem, 0);
    z-index: 0;
    height: 1em;
    width: 1em;
  }
  100% {
    transform: translate3d(0, 0, 0);
    z-index: 0;
    height: 1em;
    width: 1em;
  }
}

.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
